import React, { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { getToken, PUBLIC_URL } from "../utils/common";
import Login from "../pages/auth/Login";
import Page404 from "../pages/404/Index";
import Error from "../pages/error/Index";
import Loadable from "../components/Lodable";

// ? Converted all imports to loadable base
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Index")));
const UsersList = Loadable(lazy(() => import("../pages/userManagement/Index")));
const CreateUser = Loadable(
  lazy(() => import("../pages/userManagement/Create"))
);
const UpdateUser = Loadable(
  lazy(() => import("../pages/userManagement/Update"))
);
const UpdateProfile = Loadable(
  lazy(() => import("../pages/userManagement/UpdateProfile"))
);
const RolesList = Loadable(lazy(() => import("../pages/roleManagement/Index")));
const PermissionsList = Loadable(
  lazy(() => import("../pages/permissionManagement/Index"))
);
const BoreExpenseAmountList = Loadable(
  lazy(() => import("../pages/boreExpenseAmount/Index"))
);

const CreateBoreExpenseAmount = Loadable(
  lazy(() => import("../pages/boreExpenseAmount/Create"))
);
const UpdateBoreExpenseAmount = Loadable(
  lazy(() => import("../pages/boreExpenseAmount/Update"))
);

const BoreReceivedAmountList = Loadable(
  lazy(() => import("../pages/boreReceivedAmount/Index"))
);

const CreateBoreReceivedAmount = Loadable(
  lazy(() => import("../pages/boreReceivedAmount/Create"))
);
const UpdateBoreReceivedAmount = Loadable(
  lazy(() => import("../pages/boreReceivedAmount/Update"))
);

const BoreBillList = Loadable(lazy(() => import("../pages/boreBill/Index")));

const CreateBoreBill = Loadable(lazy(() => import("../pages/boreBill/Create")));
const UpdateBoreBill = Loadable(lazy(() => import("../pages/boreBill/Update")));

// Vikas
const AgentPerformance = Loadable(
  lazy(() => import("../pages/agentPerformance/Index"))
);
const ViewAllReport = Loadable(
  lazy(() => import("../pages/viewAllReport/Index"))
);
const DepthReport = Loadable(
  lazy(() => import("../pages/depthReport/Index"))
);
const AgentReport = Loadable(
  lazy(() => import("../pages/agentReport/Index"))
);
const PrivateRoute = ({ children }) => {
  const isAuthenticated = getToken();
  return isAuthenticated ? children : <Navigate to="/" />;
};

const AllRoutes = () => {
  return (
    <React.StrictMode>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route
            key="dashboard"
            path="/dashboard"
            element={
              <PrivateRoute key="pk1">
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            key="users-list"
            path="/users-list"
            element={
              <PrivateRoute key="pk2">
                <UsersList />
              </PrivateRoute>
            }
          />
          <Route
            key="create-user"
            path="/create-user"
            element={
              <PrivateRoute key="pk3">
                <CreateUser />
              </PrivateRoute>
            }
          />
          <Route
            key="update-user"
            path="/update-user"
            element={
              <PrivateRoute key="pk4">
                <UpdateUser />
              </PrivateRoute>
            }
          />
          <Route
            key="update-profile"
            path="/update-profile"
            element={
              <PrivateRoute key="pk5">
                <UpdateProfile />
              </PrivateRoute>
            }
          />
          <Route
            key="role-list"
            path="/role-list"
            element={
              <PrivateRoute key="pk6">
                <RolesList />
              </PrivateRoute>
            }
          />
          <Route
            key="permission-list"
            path="/permission-list"
            element={
              <PrivateRoute key="pk6">
                <PermissionsList />
              </PrivateRoute>
            }
          />
          <Route
            key="bore-expense-amount-list"
            path="/bore-expense-amount-list"
            element={
              <PrivateRoute key="pk7">
                <BoreExpenseAmountList />
              </PrivateRoute>
            }
          />
          BoreExpenseAmount
          <Route
            key="create-bore-expense-amount"
            path="/create-bore-expense-amount"
            element={
              <PrivateRoute key="pk8">
                <CreateBoreExpenseAmount />
              </PrivateRoute>
            }
          />
          <Route
            key="update-bore-expense-amount"
            path="/update-bore-expense-amount"
            element={
              <PrivateRoute key="pk8">
                <UpdateBoreExpenseAmount />
              </PrivateRoute>
            }
          />
          <Route
            key="bore-received-amount-list"
            path="/bore-received-amount-list"
            element={
              <PrivateRoute key="pk8">
                <BoreReceivedAmountList />
              </PrivateRoute>
            }
          />
          <Route
            key="create-bore-received-amount"
            path="/create-bore-received-amount"
            element={
              <PrivateRoute key="pk8">
                <CreateBoreReceivedAmount />
              </PrivateRoute>
            }
          />
          <Route
            key="update-bore-received-amount"
            path="/update-bore-received-amount"
            element={
              <PrivateRoute key="pk8">
                <UpdateBoreReceivedAmount />
              </PrivateRoute>
            }
          />
          <Route
            key="bore-bill-list"
            path="/bore-bill-list"
            element={
              <PrivateRoute key="pk9">
                <BoreBillList />
              </PrivateRoute>
            }
          />
          <Route
            key="create-bore-bill"
            path="/create-bore-bill"
            element={
              <PrivateRoute key="pk8">
                <CreateBoreBill />
              </PrivateRoute>
            }
          />
          <Route
            key="update-bore-bill"
            path="/update-bore-bill"
            element={
              <PrivateRoute key="pk8">
                <UpdateBoreBill />
              </PrivateRoute>
            }
          />

          
          {/* // Vikas */}
          <Route
            key="agent-performance"
            path="/agent-performance"
            element={
              <PrivateRoute key="pk8">
                <AgentPerformance />
              </PrivateRoute>
            }
          />
          <Route
            key="depth-report"
            path="/depth-report"
            element={
              <PrivateRoute key="pk8">
                <DepthReport />
              </PrivateRoute>
            }
          />
          <Route
            key="view-all-report"
            path="/view-all-report"
            element={
              <PrivateRoute key="pk8">
                <ViewAllReport />
              </PrivateRoute>
            }
          />
          <Route
            key="agent-report"
            path="/agent-report"
            element={
              <PrivateRoute key="pk8">
                <AgentReport />
              </PrivateRoute>
            }
          />

          <Route key="/" path="/" element={<Login />} exact />
          <Route key="error" path="/error" element={<Error />} exact />
          <Route key="*" path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default AllRoutes;
